/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "OTT"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netflix.com"
  }, "넷플릭스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/premium"
  }, "유튜브 프리미엄")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.disneyplus.com"
  }, "디즈니+")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/apple-tv-plus/"
  }, "Apple TV+")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.primevideo.com"
  }, "Amazon Prime")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://play.coupang.com"
  }, "쿠팡 플레이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.spotvnow.co.kr/"
  }, "SpoTV Now")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mlb.com/live-stream-games/subscribe"
  }, "MLB.TV")), "\n", React.createElement(_components.li, null, "케이블 TV"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tving.com"
  }, "티빙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wavve.com"
  }, "웨이브")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://watcha.com"
  }, "왓챠")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aniplustv.com"
  }, "애니플러스")), "\n"), "\n", React.createElement(_components.h2, null, "클라우드 스토리지 + ɑ"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nid.naver.com/membership/join"
  }, "네이버 멤버십 플러스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://one.google.com/about/"
  }, "Google One")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.icloud.com"
  }, "iCloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://drive.kakao.com/"
  }, "톡서랍 플러스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.microsoft.com/ko-kr/microsoft-365/onedrive/online-cloud-storage"
  }, "MS OneDrive")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.microsoft.com/ko-kr/microsoft-365/buy/compare-all-microsoft-365-products"
  }, "Microsoft 365")), "\n"), "\n", React.createElement(_components.h2, null, "생산성 도구"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.dropbox.com"
  }, "DropBox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.adobe.com"
  }, "Adobe Creative Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.toss.im/article/toss-prime"
  }, "토스 프라임")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://workspace.google.com/"
  }, "Google Workspace")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ifttt.com"
  }, "IFTTT")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://x.com/i/premium_sign_up"
  }, "X Preimum")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.linkedin.com"
  }, "LinkedIn")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/pricing"
  }, "GitHub Pro")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/features/copilot"
  }, "GitHub Copilot")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://openai.com/chatgpt/"
  }, "ChatGPT")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gemini.google.com"
  }, "Google Gemini")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.raycast.com/pricing"
  }, "Raycast")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.deepl.com/en/pro"
  }, "DeepL Pro")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.grammarly.com/premium"
  }, "Grammarly Premium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/ko-kr/"
  }, "Jetbrain")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.1password.com"
  }, "1password")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://setapp.com"
  }, "Set App")), "\n", React.createElement(_components.li, null, "VPN"), "\n"), "\n", React.createElement(_components.h2, null, "노트"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/pricing"
  }, "Notion")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app"
  }, "Bear")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dayoneapp.com"
  }, "Day One")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://obsidian.md/pricing"
  }, "Obsidian")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://roamresearch.com/"
  }, "Roam Research")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://diarly.app"
  }, "Diarly")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://whooing.com"
  }, "후잉")), "\n"), "\n", React.createElement(_components.h2, null, "음악"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://music.apple.com"
  }, "Apple Music")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://open.spotify.com"
  }, "Spotify")), "\n"), "\n", React.createElement(_components.h2, null, "정보 수집"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://feedly.com/i/pro"
  }, "Feedly")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/membership"
  }, "Medium Membership")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.millie.co.kr"
  }, "밀리의 서재")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/"
  }, "한국경제신문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.barrons.com/"
  }, "Barron’s")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://seekingalpha.com"
  }, "Seeking Alpha")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://seoulpi.io"
  }, "SPI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gurufocus.com"
  }, "Guru Focus")), "\n"), "\n", React.createElement(_components.h2, null, "기타"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.rescuetime.com"
  }, "Rescue Time")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wakatime.com"
  }, "Waka TIme")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lucidchart.com"
  }, "Lucidchart")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sleepcycle.com"
  }, "Sleep Cycle")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.flightyapp.com"
  }, "Flightly")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.flightconnections.com"
  }, "Flight Connection")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
